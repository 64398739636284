<template>
	<view>
		<u-navbar title="修改登录密码" title-color="#00000" :background="background">
		</u-navbar>

		<view class="u-p-30">
			<view class="u-font-40">设置新的登录密码</view>
		</view>

		<view class="u-p-l-50">
			<u-form :model="form" ref="uForm" label-width="140px">
				<u-form-item label="新密码">
					<u-input v-model="form.password" />
				</u-form-item>
				<u-form-item label="确认密码">
					<u-input v-model="form.password2" />
				</u-form-item>
			</u-form>
		</view>
		<view class="u-m-t-80 u-padding-30">
			<u-button type="primary" @click="save" :loading="saveLoading"> 确 认 </u-button>
		</view>
	</view>
</template>

<script>
	
	export default {
		data() {
			return {
				background: {
					backgroundColor: '#FBDF3E',
				},
				form: {

				},
				code: '',
				codeText: '',
				saveLoading: false,
			}
		},
		methods: {
			async save() {
				if (this.form.password != this.form.password2) {
					this.$u.toast('输入的两次密码不对应');
					return false;
				}
				const params = JSON.parse(JSON.stringify(this.form));
				this.saveLoading = true;
let{					status
				} = await this.$fetch(this.$api.updateUser, params, 'POST')

				this.saveLoading = false;
				if (status === 20000) {
					this.$u.toast('修改密码成功');
					setTimeout(() => {
						this.$router.push('/login');
					})
				} else {
					this.$u.toast('修改密码失败');
				}
			}
		}
	}
</script>

<style scoped>
	page {
		background-color: #FFFFFF;
	}
</style>
